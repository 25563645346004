import Axios from 'axios';
import config from './config.service';
import { capitalize } from 'lodash-es';
import env from '@env';
import { Theme } from '@loopia-group/services';
import store from '../store';
import { StoreActions } from '../store/const.enum';
import { setConfigValues } from '@loopia-group/services';

let prevThemeStyleElm: Element;
const styleElmsCache: Record<string, Element> = Object.create(null);
const switchThemeLocal = (theme: string) => {
  // eslint-disable-next-line no-console
  console.log('switchTheme', theme);

  let themeStyleElm: Element;

  if (styleElmsCache[theme]) {
    themeStyleElm = styleElmsCache[theme];
  } else {
    const styleElms = document.querySelectorAll('head style');
    const stylesArray = Array.from(styleElms);

    stylesArray.forEach((style: Element) => {
      if (style.innerHTML.includes('ws_dev_theme_dynamic_')) {
        const content = style.innerHTML || '';
        const matches = content.match(/ws_dev_theme_dynamic_(\w*)/);
        styleElmsCache[matches![1]] = style;
        style.setAttribute('type', 'ws-disabled');
      }
    });

    themeStyleElm = styleElmsCache[theme];
  }

  if (prevThemeStyleElm) {
    prevThemeStyleElm.setAttribute('type', 'ws-disabled');
  }

  if (!themeStyleElm) {
    // theme element not added to DOM yet, try again later
    return setTimeout(() => {
      switchThemeLocal(theme);
    }, 1000);
  }

  themeStyleElm.setAttribute('type', 'text/css');
  prevThemeStyleElm = themeStyleElm;
};

const linkId = 'ws-theme-stylesheet';
const switchThemeRemote = async (theme: string) => {
  const { data: manifest } = await Axios.get(
    config.assetUrl + '/manifest.json'
  );

  let linkElm = document.getElementById(linkId);
  if (!linkElm) {
    linkElm = Object.assign(
      document.createElement('link'),
      {
        id: linkId,
        rel: 'stylesheet',
        'data-type': 'theming-link',
      });

    document.body.appendChild(linkElm);
  }

  linkElm.setAttribute(
    'href',
    config.assetUrl + manifest[`theme${capitalize(theme)}.css`]
  );
};

export const switchTheme = async (theme: Theme) => {
  config.theme = theme;
  store.dispatch('theme/' + StoreActions.SET_THEME, {theme});
  setConfigValues(config);
  env.development ? switchThemeLocal(theme) : await switchThemeRemote(theme);
};

if (env.development) {
  switchTheme(config.theme);
}

// @ts-ignore
window.switchTheme = switchTheme;